<template>
  <div class="landing container">
    <div class="hero text-center">
      <h1 class="display-1">Voice Journal</h1>
      <p class="lead">Turn your Telegram voice messages into beautiful journals.</p>
      <img :src="require('@/assets/voice.jpg')" alt="Telegram" class="img-fluid mb-4" />
      <!-- <div class="input-group mb-3">
        <select class="form-select" v-model="countryCode" style="max-width: 20%;">
          <option value="+1">+1 (US)</option>
          <option value="+91">+91 (IN)</option>
          <option value="+44">+44 (UK)</option> -->
          <!-- Add more country codes as needed -->
        <!-- </select>
        <input type="text" placeholder="Enter your mobile number" v-model="mobileNumber" class="form-control" />
      </div> -->
      <button @click="startUsing" class="btn btn-success btn-lg">Start Using Voice Journal</button>
      <br/>
      <!-- <span v-if="!isLoaded">Loading...</span>
            <telegram-login-temp
              mode="callback"
              telegram-login="VoiceJournalBot"
              @loaded='telegramLoadedCallbackFunc'
              @callback="login"
            /> -->
    </div>
    <div class="features row mt-5">
      <div class="feature col-md-4 text-center mb-4">
        <h2 class="h4">Effortless Journaling</h2>
        <p>Voice Journal automatically transcribes your Telegram voice messages, making it easier than ever to capture your thoughts and experiences.</p>
        <img :src="require('@/assets/sound.png')" alt="Effortless Journaling" class="img-fluid" />
      </div>
      <div class="feature col-md-4 text-center mb-4">
        <h2 class="h4">Organize and Cherish</h2>
        <p>Voice Journal helps you organize your voice messages into beautiful journals, allowing you to relive your memories anytime.</p>
        <img :src="require('@/assets/organize2.gif')" alt="Organize and Cherish" class="img-fluid" />
      </div>
      <div class="feature col-md-4 text-center mb-4">
        <h2 class="h4">Ask questions about your journal</h2>
        <p>With help of our assistant, ask questions about your old journals</p>
        <img :src="require('@/assets/ask-questions.gif')" alt="Organize and Cherish" class="img-fluid" />
      </div>
    </div>
    <!-- <div class="pricing row mt-5">
      <div class="col-12 text-center mb-4">
        <h2 class="display-4">Pricing</h2>
        <p class="lead">Choose a plan that's right for you.</p>
      </div>
      <div class="col-md-4 text-center mb-4">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Trial</h3>
            <p class="card-text">7 days</p>
            <ul class="list-unstyled">
              <li>Transcription of up to 30 minutes of voice messages a day</li>
              <li>5 journals per day</li>
            </ul>
            <button class="btn btn-primary">Get Started</button>
          </div>
        </div>
      </div>
      <div class="col-md-4 text-center mb-4">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Standard</h3>
            <p class="card-text">$9.99/month</p>
            <ul class="list-unstyled">
              <li>Unlimited journals per day</li>
              <li>Voice message upto 1 hour per journal</li>
            </ul>
            <button class="btn btn-primary">Get Started</button>
          </div>
        </div>
      </div>
      <div class="col-md-4 text-center mb-4">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Believer</h3>
            <p class="card-text">$50</p>
            <ul class="list-unstyled">
              <li>For early believers.</li>
              <li>One time payment </li>
              <li>Free for lifetime </li>
            </ul>
            <button class="btn btn-primary">Get Started</button>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script setup>
// import { ref } from 'vue'

// const isLoaded = ref(false)
// import { ref } from 'vue';

// const mobileNumber = ref('');
// const countryCode = ref('+1');
import { useRouter } from 'vue-router';

const router = useRouter();

const startUsing = () => {
  // Handle button click, likely navigate to a sign-up or download page
  // window.location.href = "https://tally.so/r/mO0Y77"; // Replace with your app store link
  router.push('/login');
}

// function login(user) {
//           // Handle login logic here
//           console.log('Logging in:', user);
//         }
        
// function telegramLoadedCallbackFunc () {
//           console.log("scrupt is loaded")
//           isLoaded.value = true
// }
</script>

<style scoped>
.landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #fff7f2;
  padding: 2rem 1rem;
  font-family: sans-serif;
}

.hero {
  text-align: center;
  margin-bottom: 5rem;
}

.hero .display-1 {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.hero .lead {
  font-size: 1.2rem;
  color: #777;
  margin-bottom: 1rem;
}

.hero img {
  width: 80%;
}

.features,
.pricing {
  width: 100%;
}

.feature h2,
.pricing h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.feature p,
.pricing p {
  font-size: 1rem;
  color: #777;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2rem;
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.card-text {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.list-unstyled {
  list-style: none;
  padding: 0;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .features {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .feature,
  .pricing .card {
    margin-bottom: 3rem;
    
  }
}
</style>
