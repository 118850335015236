<template>
    <div class="chat-widget" @click="toggleChatSidebar">
      <i class="fas fa-comments"></i>
    </div>
  </template>
  
  <script>
  import { useMainStore } from '../store';
  
  export default {
    name: 'ChatWidget',
    setup() {
      const store = useMainStore();
      const toggleChatSidebar = () => store.toggleChatSidebar();
  
      return {
        toggleChatSidebar
      };
    }
  };
  </script>
  
  <style scoped>
  .chat-widget {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #007bff;
    color: #ffffff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    z-index: 1100;
  }
  </style>
  