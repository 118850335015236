import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/Home.vue';
import LoginView from '../views/LoginView.vue';
import axios from 'axios'
// import RegisterView from '../views/Register.vue';
// import SettingsPage from '../views/Settings.vue'
import LandingPage from '../views/LandingPage.vue'


const routes = [
  { path: '/', redirect: '/landing' },
  { path: '/landing', component: LandingPage, meta: { title: 'Voice Journal', description: 'Turn voice messages to beautiful journals'} },
  // { path: '/settings', component: SettingsPage},
  { path: '/home', component: HomeView, meta: { requiresAuth: true } },
  { path: '/login', name: 'Login', component: LoginView },
  // { path: '/register', component: RegisterView },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  const { title } = to.meta;
  const defaultTitle = 'Default Title';

  document.title = title || defaultTitle

  if(to.matched.some(record => record.meta.requiresAuth)) {
    try {
      const response = await axios.get('https://www.voice-journal.com/auth/check-auth', { withCredentials: true }); // Include credentials for HttpOnly cookies
      console.log(response)
      if (response.data.status == "success") {
        next();
      } else {
        console.log("Not authenticated. Redirect to login")
        next('/login'); // Redirect to login if not authenticated
      }
    } catch (error) {
      console.error('Error checking authentication:', error);
      next('/login'); // Redirect to handle errors (e.g., network issues)
    }
  } else {
    next();
  }
  });

export default router;
