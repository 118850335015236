import { defineStore } from 'pinia';

export const useMainStore = defineStore('main', {
  state: () => ({
    darkMode: false,
    sidebarVisible: true,
    chatSidebarVisible: false,
    currentJournal: null
  }),
  actions: {
    toggleDarkMode() {
      this.darkMode = !this.darkMode;
    },
    toggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible;
    },
    toggleChatSidebar() {
      this.chatSidebarVisible = !this.chatSidebarVisible;
    },
    updateCurrentJournal(journal) {
        console.log('updated journal')
        this.currentJournal = journal;
    },
    getCurrentJournal() {
        return this.currentJournal;
    }
  }
});
