<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <router-link class="navbar-brand" to="/home">Voice Journal</router-link>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" href="#" @click="toggleDarkMode"><i class="fas fa-moon"></i></a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-user"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
            <router-link class="dropdown-item" to="/settings">Settings</router-link>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#" @click="logout">Logout</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { useMainStore } from '../store';
import { useRouter } from 'vue-router';

export default {
  name: 'NavBar',
  setup() {
    const store = useMainStore();
    const router = useRouter();

    const toggleDarkMode = () => store.toggleDarkMode();
    const toggleSidebar = () => store.toggleSidebar();
    const logout = () => {
      // Implement your logout logic here
      // store.logout();
      router.push('/login'); // Redirect to login page after logout
    };

    return {
      toggleDarkMode,
      toggleSidebar,
      logout
    };
  }
};
</script>

<style scoped>
.navbar {
  padding: 1rem;
}

.nav-link {
  cursor: pointer;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}
</style>
