<template>
  <div class="col content-area flex" :class="{ 'dark-mode': darkMode }">
    <div v-if="currentJournal">
      <div v-if="isEditing">
        <p class="journal-title">{{ currentJournal.title }}</p>
        <p class="journal-date">{{ currentJournal.date }}</p>
        <editor-content :editor="editor" class="editor" />
        <button @click="saveEdit" class="save-button">Save</button>
      </div>
      <div v-else>
        <p class="journal-title">{{ currentJournal.title.replaceAll('"', '') }}</p>
        <p class="journal-date">{{ currentJournal.date }}</p>
        <p class="journal-text" v-html="currentJournal.content"></p>
        <button @click="startEdit" class="edit-button">Edit</button>
      </div>
    </div>
    <div v-else class="no-journals">
      <p>Blank canvas – paint your masterpiece!.</p>
      <img :src="require('@/assets/background.webp')"/>
    </div>
    <ChatWidget />
    <ChatSidebar />
  </div>
</template>

<script>
import { useMainStore } from '../store';
import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
import ChatWidget from '../components/ChatWidget.vue';
import ChatSidebar from '../components/ChatSidebar.vue';
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';

export default {
  name: 'ContentArea',
  components: {
    ChatWidget,
    ChatSidebar,
    EditorContent,
  },
  setup() {
    const store = useMainStore();
    const darkMode = computed(() => store.darkMode);
    const currentJournal = computed(() => store.getCurrentJournal());
    const isEditing = ref(false);

    const editor = ref(
      new Editor({
        extensions: [StarterKit],
        content: '',
      })
    );

    onMounted(() => {
      if (currentJournal.value) {
        editor.value.commands.setContent(currentJournal.value.content);
      }
    });

    onBeforeUnmount(() => {
      editor.value.destroy();
    });

    const startEdit = () => {
      isEditing.value = true;
      editor.value.commands.setContent(currentJournal.value.content);
    };

    const saveEdit = () => {
      const updatedContent = editor.value.getHTML();
      currentJournal.value.content = updatedContent;
      store.updateCurrentJournal(currentJournal.value);
      isEditing.value = false;
    };

    return {
      darkMode,
      currentJournal,
      isEditing,
      editor,
      startEdit,
      saveEdit,
    };
  },
};
</script>

<style scoped>
.no-journals {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.no-journals img {
  width: 50%;
}

.no-journals p {
  font-size: 2rem;
  color: gray;
}

.content-area {
  margin-left: 20px;
  padding: 20px;
  width: 100%;
  background-color: #fff7;
  border-left: 1px solid gray;
}

.content-area.dark-mode {
  background-color: #335;
}

.content-area p {
  color: #2c3e50;
}

.content-area.dark-mode p {
  color: #fff;
}

.journal-title {
  font-size: 2.5rem;
  font-family: 'sans-serif';
  margin-bottom: 20px;
  color: #2c3e50;
}

.journal-date {
  color: #999;
  font-size: 0.9em;
  margin-bottom: 20px;
}

.journal-text {
  color: #555;
  margin-bottom: 20px;
  padding: 5px;
  font-size: 1.5rem;
  text-align: justify;
  overflow-y: auto; 
}

.editor {
  margin-bottom: 20px;
  min-height: 300px;
  border: 1px solid #ccc;
  padding: 10px;
}

.edit-button,
.save-button {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
}

.edit-button:hover,
.save-button:hover {
  background-color: #2980b9;
}

@media (min-width: 768px) {
  .content-area {
    margin-left: 60px;
    padding: 40px 20px;
    max-width: 800px; /* Set the maximum width */
  }
}

@media (max-width: 767px) {
  .journal-title {
    font-size: 2rem;
  }

  .journal-text {
    font-size: 1.2rem;
  }

  .edit-button,
  .save-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .no-journals p {
    font-size: 1.5rem;
  }

  .no-journals img {
    width: 80%;
  }
}
</style>
