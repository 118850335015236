<template>
    <div class="chat-sidebar" :class="{ 'visible': chatSidebarVisible }">
      <div class="chat-header">
        <h5>Ask anything from your journal</h5>
        <button class="btn btn-link" @click="toggleChatSidebar"><i class="fas fa-times"></i></button>
      </div>
      <div class="chat-box" id="chat-box">
        <!-- Chat messages will go here -->
      </div>
      <div class="chat-bar">
        <input type="text" id="chat-input" v-model="message" placeholder="Type a message...">
        <button @click="sendMessage"><i class="fas fa-paper-plane"></i></button>
      </div>
    </div>
  </template>
  
  <script>
  import { useMainStore } from '../store';
  import { ref, computed } from 'vue';
  
  export default {
    name: 'ChatSidebar',
    setup() {
      const store = useMainStore();
      const chatSidebarVisible = computed(() => store.chatSidebarVisible);
      const message = ref('');
  
      const toggleChatSidebar = () => store.toggleChatSidebar();
  
      const sendMessage = () => {
        if (message.value.trim() !== '') {
          const chatBox = document.getElementById('chat-box');
          const messageElement = document.createElement('div');
          messageElement.className = 'message';
          messageElement.textContent = message.value;
          chatBox.appendChild(messageElement);
          message.value = '';
          chatBox.scrollTop = chatBox.scrollHeight;
        }
      };
  
      return {
        chatSidebarVisible,
        toggleChatSidebar,
        message,
        sendMessage
      };
    }
  };
  </script>
  
  <style scoped>
  .chat-sidebar {
    background-color: #ffffff;
    width: 20%;
    height: 60%;
    position: fixed;
    bottom: 10%;
    left: 40%;
    transform: translateY(100%);
    transition: transform 0.3s ease, visibility 0.3s ease;
    visibility: hidden;
    z-index: 1000;
    box-shadow: 1px -4px 1px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  .chat-sidebar.visible {
    transform: translateY(0);
    visibility: visible;
  }
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dee2e6;
    padding: 10px;
    background-color: #f8f9fa;
  }
  .chat-box {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    border-radius: 2px;
  }
  .chat-bar {
    padding: 10px;
    background-color: #ffffff;
  }
  .chat-bar input {
    width: calc(100% - 50px);
    padding: 10px;
    border: 1px solid #dee2e6;
    border-radius: 5px;
  }
  .chat-bar button {
    width: 40px;
    border: none;
    background: none;
    color: #007bff;
    font-size: 1.5em;
  }
  </style>
  