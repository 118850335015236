  <template>

      <div>
          <NavBar/>
             <!-- Callback mode -->
          <vue-telegram-login 
            mode="callback"
            telegram-login="VoiceJournalBot"
            @callback="login" />
        <div class="container">
          <div class="login">
           
          <!-- Callback mode -->
          <span v-if="!isLoaded">Loading...</span>
            <telegram-login-temp
              mode="callback"
              telegram-login="VoiceJournalBot"
              @loaded='telegramLoadedCallbackFunc'
              @callback="login"
            />
         
            
          </div> 
        </div>
      </div>
    </template>
    <script setup>
      import { ref } from 'vue';
      import { useRouter } from 'vue-router';
      import NavBar from '../components/NavBar';
      import { telegramLoginTemp } from 'vue3-telegram-login';
      
      const isLoaded = ref(false);
      const BACKEND_URL = "https://www.voice-journal.com/auth/verify-telegram-login";
      const router = useRouter();
      
      const login = async (user) => {
        // Handle login logic here
        console.log('Logging in:', user);
        const result = await verifyTelegramInfo(user);
        console.log(result)
        console.log(result[0])
        if (result[0].status == "success") {
          console.log("login success");
          router.push('/home');
        } else {
          console.log("result error");
        }
      };

     const verifyTelegramInfo = async (data) => {
      try {
        const response = await fetch(BACKEND_URL, {
          method: "POST",
          headers: {"Content-Type": "application/json" },
          body: JSON.stringify(data),
          credentials: 'include'
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        return result;
      } catch (error) {
        console.log("There was a problem with VerifyAuth fetch operation:", error);
      }
        
      };
      
      const telegramLoadedCallbackFunc = () => {
        console.log("script is loaded");
        isLoaded.value = true;
      };
    </script>
    
    <style scoped>
    .container {
      padding-top: 50px;
    }
    .login {
      max-width: 400px;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 10px;
      background: #fff;
    }
    </style>
    