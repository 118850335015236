<template>
    <div class='container'>
    <NavBar/>
    <div class="centralized-container">

      <div class="container-fluid">

        <div class="row">
          <!-- <SmallSidebar /> -->
          <SideBar >         
          </SideBar>
          <ContentArea />
          <!-- <ChatSidebar />
          <ChatWidget /> -->
        </div>
      </div>
    </div>
    </div>
  </template>
  
  <script>
//   import SmallSidebar from '../components/SmallSidebar.vue';
  import NavBar from '../components/NavBar.vue'
  import SideBar from '../components/SideBar.vue';
  import ContentArea from '../components/ContentArea.vue';
//   import ChatSidebar from '../components/ChatSidebar.vue';
//   import ChatWidget from '../components/ChatWidget.vue';
  
  export default {
    name: 'HomeView',
    components: {
    //   SmallSidebar,
      NavBar,
      SideBar,
      ContentArea,
    //   ChatSidebar,
    //   ChatWidget
    }
  };
  </script>
  
  <style scoped>
  .centralized-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .container-fluid {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  </style>
  