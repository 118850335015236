<template>
    <div class="sidebar flex" :class="{ 'dark-mode': darkMode, 'visible': sidebarVisible }">
      <h4>All Journals</h4>
      <div class="search-container input-group mb-3">
        <input
            type="text"
            v-model="searchText"
            class="form-control"
            placeholder="Search journals..."
            aria-label="Search journals"
            aria-describedby="button-search"
        />
        <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" id="button-search">
            <i class="fas fa-search"></i>
            </button>
        </div>
    </div>
        <div v-if="loading" class="spinner">Loading...</div>
        <ul v-else class="list-group">
        <li v-if="filteredJournalItems.length === 0" class="no-results">
            No journals found for your search.
        </li>
        <li
            v-else
            class="list-group-item journal-list-item"
            v-for="(item, index) in filteredJournalItems"
            :key="index"
            @click="handleJournalItemClick(item, index)"
        >
            <div class="journal-item">
            <div class="col">
                <span class="journal-time">{{ formattedDates[index].time }}</span><br />
                <span class="journal-date">{{ formattedDates[index].date }}</span>
            </div>
            <div class="col">
                <span class="journal-title">{{ item.title.slice(0, 20).replaceAll('"', '') }}...</span>
                <!--     -->
            </div>
            </div>
        </li>
        </ul>
    </div>
  </template>
  
  
  <script>
  import { useMainStore } from '../store';
  import { ref, computed, onMounted } from 'vue';
  import axios from 'axios';

  export default {
    name: 'SideBar',
    setup() {
      const store = useMainStore();
      const darkMode = computed(() => store.darkMode);
      const sidebarVisible = computed(() => store.sidebarVisible);
      const journalItems = ref([]);
      const loading = ref(true);
      const searchText = ref('');

      const apiClient = axios.create({
            baseURL: 'https://www.voice-journal.com',
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            },
          });

      const formattedDates = computed(() => {
        return journalItems.value.map(item => {
            try {
                const date = new Date(item.timestamp);
                const formattedDate = date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                });
                const formattedTime = date.toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true // Use false for 24-hour format
                });
                return {
                    date: formattedDate,
                    time: formattedTime
                };
            } catch (error) {
                console.error('Error formatting date for item:', item, error);
                return {
                    date: 'Invalid Date',
                    time: 'Invalid Time'
                };
            }
        });
      });

      const filteredJournalItems = computed(() => {
        const searchTextLower = searchText.value.toLowerCase();
        return journalItems.value.filter(item => {
          const titleLower = item.title.toLowerCase();
          const contentLower = item.content.toLowerCase();
          return (
            titleLower.includes(searchTextLower) ||
            contentLower.includes(searchTextLower)
          );
        });
      });

      const fetchJournalItems = async () => {
        try {
          // const response_auth = await apiClient.get('/auth/check-auth', { withCredentials: true }); // Include credentials for HttpOnly cookies
          // console.log("AUTH:" + response_auth)

          const responseAuth = await fetch('https://www.voice-journal.com/auth/check-auth', {
            method: "GET",
            credentials: 'include'
          });

          console.log("Auth:" + responseAuth);

          const response = await apiClient.get('/getJournals', { withCredentials: true });
          journalItems.value = response.data.result;
        } catch (error) {
          console.error('Failed to fetch journal items:', error);
        } finally {
          loading.value = false;
        }
      };

      const handleJournalItemClick =  (item, index) =>  {

        store.updateCurrentJournal({'title': item.title, 'content': item.content, 'timestamp': formattedDates[index]})
      };

      onMounted(() => {
        fetchJournalItems();
      });

      return {
        darkMode,
        sidebarVisible,
        journalItems,
        loading,
        formattedDates,
        searchText,
        filteredJournalItems,
        handleJournalItemClick
      };
    }
  };
  </script>
  
  
  <style scoped>

.sidebar {
  padding: 20px;
  width: 300px;
  border-right: 1px solid #ccc;
  overflow-y: auto;
}

.sidebar.dark-mode {
  background-color: #333;
  color: white;
}

.sidebar.visible {
  display: block;
}

/* .search-container {
  margin-bottom: 20px;
} */

.search-bar {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

/* .spinner {
  text-align: center;
  padding: 20px;
} */
/* 
.list-group {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.list-group-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.list-group-item:hover {
  background-color: #f5f5f5;
} */

/* .no-results {
  text-align: center;
  padding: 20px;
} */
/* 
.journal-item {
  display: flex;
  justify-content: space-between;
}

.journal-date,
.journal-title,
.journal-content {
  flex: 1;
  /* padding: 0 10px; */
  /*
}

.journal-time {
    font-weight: 600;
}

.journal-date {
  text-align: left;
  font-size:1em;
  font-weight:400;
  padding-left:2px;
  color: gray
}

.journal-title {
  text-align: justify-content;
  font-size: 1em;
  font-weight: 800;
  color:gray;
}

.journal-content {
  text-align: right;
} */



.journal-title {
  text-align: left;
  font-size: 1em;
  font-weight: 800;
  color:gray;
}

.journal-date,
.journal-title,
.journal-content {
  flex: 1;  
}

.search-container {
    margin-bottom: 10px;
}

.search-bar {
    width: 100%;
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

/* .no-results {
text-align: center;
padding: 10px;
color: #ccc;
} */

.spinner {
    text-align: center;
    padding: 20px;
}

.sidebar {
    background-color: #ffffff;

    padding: 20px;
    height: 100vh;
    /* position: fixed;
    top: 0;
    bottom: 0; */
    transition: transform 0.3s ease;
    transform: translateX(-250px);
    /*left: 60px;  Adjusted to account for SmallSidebar */
}

@media (min-width: 768px) {
    .sidebar {
        transform: translateX(0);
    }
}

.list-group-item {
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    margin-bottom: 10px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s;
}


.list-group-item:hover {
    background-color: #f1f1f1;
}

.text-muted {
    display:inline-block;
}

.spinner {
  text-align: center;
  font-size: 1.5rem;
  padding: 1rem;
}

.list-group {
  margin: 20px 0;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.no-results {
  text-align: center;
  color: #999;
  font-style: italic;
}

.journal-list-item {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.journal-list-item:hover {
  background-color: #f0f0f0;
  color: #007bff;
  cursor: pointer;
}

.journal-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.journal-time, .journal-date {
  font-size: 0.85rem;
  color: #666;
}

.journal-title {
  font-weight: bold;
  color: #333;
}

.journal-title:hover {
  color: #0056b3;
}

/* Mobile responsive */
@media (max-width: 576px) {
  .journal-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .journal-title {
    margin-top: 5px;
  }
}
  </style>
  